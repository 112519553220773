<template>
    <el-scrollbar class="news-detail-container" :native="false">
        <div class="news-detail">
            <div class="news-title">{{newsInfo.title}}</div>
            <div class="news-content">
                <div v-html="newsInfo.content"></div>
            </div>
        </div>
    </el-scrollbar>
</template>

<script>
    export default {
        data() {
            return {
                newsId: Number(this.$route.query.newsId) || '',
                newsInfo: {
                    title: '',
                    content: '',
                }
            }
        },
        mounted() {
            this.getNewsInfo();
        },
        methods: {
            getNewsInfo() {
                this.$http.axiosGetBy(this.$api.newsInfo, {id: this.newsId}, (res) => {
                    console.log(res)
                    if (res.code === 200) {
                        this.newsInfo = res.data
                    }
                }, (err) => {
                    console.log(err)
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .news-detail-container {
        background: #f2f2f2;
        width: 100%;
        height: 100%;
        overflow: hidden;
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        .news-detail {
            width: 1100px;
            background: #fff;
            margin: 50px auto;
            box-shadow: 0 2px 10px 0 rgba(32,32,32,0.05);
            padding: 40px 50px;
            .news-title {
                text-align: center;
                font-size: 30px;
                color: #000;
                margin-bottom: 20px;
            }
            .news-content {
                ::v-deep div {
                    img {
                        max-width: 100%;
                    }
                }
            }
        }
    }
</style>